const language = {
  FR: {
    title: "Plats du jour",
    description: "Découvrez nos fonctionnalités et nos services!",
    signUp: "PRE-INSCRIPTION",
    altLogo: "Plats du jour logo"
  },
  EN: {
    title: "Daily specials",
    description: "Discover our features and services!",
    signUp: "REGISTRATION",
    altLogo: "Daily specials logo"
  },
  ES: {
    title: "Especiales del día",
    description: "Descubra nuestras características y servicios!",
    signUp: "Registro",
    altLogo: "Especiales del día logo"
  },
};

export default language;
