import React, {createContext, useState} from 'react'

export const langContext = createContext();

const supportedLangs = ['EN', 'FR', 'ES' ];

let browserLang = navigator.language.slice(0,2).toUpperCase();
//console.log(browserLang);

//Si le navigateur n'est pas dasn le tableau [0, 1, 2]
if(supportedLangs.indexOf(browserLang) === -1) {
    console.log("not supported");
    browserLang =  "EN"
}



const ConstextProvider = props => {

    const [lang, setLang] = useState(browserLang)

    console.log(lang);
    const toggleLang = changeLang => {
         setLang (changeLang)
    }

    return (
        <langContext.Provider value={{lang, toggleLang}}>
            {props.children}
        </langContext.Provider>
      )

} 

export default ConstextProvider;










