import React from 'react'
import NavbarB from '../../Components/NavbarB'
import Roger from "./Roger.gif"
import language from '../../assets/language/home'
import "./style.default.css"


export default function PrivateHome() {
  return (
    <>
    <NavbarB/>
    <div className='container p-5 mt-5  '>
        <h1 className="display-3 mb-4 text-gray">Bienvenue sur Plats du jour</h1>
        <h6 className="display-3 mb-4 text-gray">Nous vous remercions de votre inscription</h6>
        <div style={{backgroundImage: 'url("/assets/img/restaurant/photo-1526392060635-9d6019884377.jpg")'}}></div>
        <div className="div text-center">
          <h1>{language['FR'].title}</h1>
          <img className='img-fluid img-thumbnail' src={Roger} alt="Roger"/>
        </div>
        
    </div>
    </>
  )
    
}
