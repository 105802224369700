import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../context/userContext'
//import Footer from '../Components/Footer'
import NavbarB from '../Components/NavbarB'

export default function Connect() {

    const {toggleModals} = useContext(UserContext)

  return (
    <>
    <NavbarB/>
    {/* <li className="nav-item"><Link onClick={() => toggleModals("signIn")} className="nav-link text-white" to="/Connect">Se connecter</Link></li> */}
    <div className="mh-full-screen d-flex align-items-center dark-overlay"><img className="bg-image" style={{backgroundImage: 'url(/assets/img/restaurant/AdobeStock_95369453.jpeg)'}}  />
        <div className="container text-muted text-lg overlay-content py-6 py-lg-0">
            <h1 className="display-3 font-weight-bold mb-5" text-muted><Link className="text-muted" onClick={() => toggleModals("signIn")} to="/Connect">Se connecter</Link> / <Link className="text-muted" onClick={() => toggleModals("signUp")} to="/Connect">S'incrire</Link></h1>
            {/* <p className="font-weight-light mb-5">Elit adipisicing aliquip irure non consequat quis ex fugiat dolor in irure pariatur eu aute. Ea tempor nisi sit in Lorem exercitation mollit ut veniam in do eu excepteur. </p> */}
            <p className="mb-6"> <Link className="btn btn-outline-light" to="/"><i className="fa fa-home mr-2" />Retour à la page d'Accueil</Link></p>
            {/* <p className="h4 text-shadow">Error 404</p> */}
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
