import { createContext, useState, useEffect } from "react";

import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged
  } from "firebase/auth"
  import {auth} from "../firebase-config"


export function  UserContextProvider(props) {


// permet de créer un compte
const signUp = (email, pwd ) => createUserWithEmailAndPassword
(auth, email, pwd)

// permet de ce connecté
const signIn = (email, pwd ) => signInWithEmailAndPassword
(auth, email, pwd)


const [currentUser, setCurrentUser] = useState();
const [loadingData, setLoadingData] = useState(true)


// UseEffect va ce lancé juste une fois lors de la création du composant.
//il sert à controlé si l'User est connecté ou Logout
useEffect(() => {
  
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setCurrentUser(currentUser)
        setLoadingData(false)
    })

    return unsubscribe;

}, [])


    // Modal connexion
    const [modalState, setModalState] = useState({
        signUpModal: false,
        signInModal: false
    })

    const toggleModals = modal => {
        if(modal === "signIn") {
            setModalState({
                signUpModal: false,
                signInModal: true
            })
        }

        if(modal === "signUp") {
            setModalState({
                signUpModal: true,
                signInModal: false
            })
        }

        if(modal === "close") {
            setModalState({
                signUpModal: false,
                signInModal: false
            })
        }
    }
    // finish modal connxion
    return (
        <UserContext.Provider value ={{modalState, toggleModals, signUp, signIn,  currentUser}}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}

export const UserContext = createContext()
