import {Routes, Route} from "react-router-dom"
import Home from "./Pages/Home"
import Connect from "./Pages/Connect";
import Navbar from "./Components/Navbar";
import SignUpModal from "./Components/SignUpModal";
import SignInModal from "./Components/SignInModal";
import Private from "./Pages/Private/Private";
import PrivateHome from "./Pages/Private/PrivateHome";


function App() {
  return (
    <>
     {/* <Navbar/> */}
    <SignUpModal/>
    <SignInModal/>
   
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/private" element={<Private />}>
          <Route path="/private/private-home" element={<PrivateHome />} />
        </Route>
    </Routes>
    </>
    
  );
}

export default App;
