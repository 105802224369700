const language = {
    FR: {
      title: "Connexion",
      home: "Accueil",
      btnSignUp: "Se connecter",
      signIn: "Inscription",
      loGout: " Déconnexion",
      altLogo: "Plats du jour logo",
      emailAdress:"Email",
      password:"Mot de passe",
      txt1: "Vous n'avez pas encore de compte?"
        
    },
    EN: {
        title: "Login",
        home: "Home",
        btnSignUp: "Log Up",
        signIn: "Registration",
        loGout: "Log Out",
        altLogo: "Daily specials logo",
        emailAladress:"",
        password:"Password",
        txt1: "Do not have an account yet?"
        
    },
    ES: {
        title: "Acceso",
        home: "Bienvenida",
        btnSignUp: "conexión",
        signIn: "Registro",
        loGout: "cerrar sesión",
        altLogo: "Especiales del logo",
        emailAladress:"",
        password:"Contraseña",
        txt1: "Aun no tiene una cuenta?"
        
    },
  };
  
  export default language;
  