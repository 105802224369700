import React, {useContext, useRef, useEffect, useState} from 'react'
import { UserContext } from '../context/userContext'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import PWDRequisite from './PWD/PWDRequisite';
import "./PWD/PWD.css";


export default function SignUpModal() {

    const {modalState, toggleModals, signUp} = useContext(UserContext)
    console.log(signUp);
    console.log(modalState, toggleModals);

    const navigate = useNavigate();

    const [validation, setValidation] = useState("");



    /*------------------------------PWD----------------------------- */

    // const handleOnChange = (e) => {
    //   setPassword(e.target.value);
    // };

    const [pwdRequiste, setPWDRquisite] = useState(false)
    const [checks, setChecks] = useState({
      capsLetterCheck: false,
      numberCheck: false,
      pwdlengthCheck: false,
      specialCharCheck: false,
    })
   
    const handleOnFocus = () => {
      setPWDRquisite(true);
    };
  
    const handleOnBlur = () => {
      setPWDRquisite(false);
    };
  
    const handleOnKeyUp = (e) => {
      const { value } = e.target;
      const capsLetterCheck = /[A-Z]/.test(value);
      const numberCheck = /[0-9]/.test(value);
      const pwdLengthCheck = value.length >= 8;
      const specialCharCheck = /[!@#$%^&*]/.test(value);
      setChecks({
        capsLetterCheck,
        numberCheck,
        pwdLengthCheck,
        specialCharCheck,
      });
    };
/*------------------------------PWD----------------------------- */
    const inputs = useRef([])
    const addInputs = el => {
      if(el && !inputs.current.includes(el)){
        inputs.current.push(el)
      }
    }  
    const formRef = useRef();
  


    const handleForm = async (e) => {
        e.preventDefault()

        if (setChecks === true) {
          setValidation("6 caracters min")
          return;

        } else if(inputs.current[1].value !== inputs.current[2].value) {
               setValidation("passwords not match")
               return;
              
           }

        // if((inputs.current[1].value.length || inputs.current[2].value.length) < 5) {
        //     setValidation("6 caracters min")
        //     return;
        // }
        // else if(inputs.current[1].value !== inputs.current[2].value) {
        //     setValidation("passwords not match")
        //     return;
            
        // }

        // if((inputs.current[1].value.length || inputs.current[2].value.length) < 6) {
        //     setValidation("6 characters min")
        //     return; of the past

        //   }
        //   else if(inputs.current[1].value !== inputs.current[2].value) {
        //     setValidation("Passwords do not match")
        //     return;
        //   }

          
        console.log(e);


        try {
            const cred = await signUp(
                inputs.current[0].value,
                inputs.current[1].value,
            )

            formRef.current.reset();
            setValidation("")
            console.log(cred);
            toggleModals("close")

            navigate("/private/private-home")

        } catch (error) {
            if (error.code === "auth/invalid-email") {
                setValidation("Email format invalid")
                
            }

            if (error.code === "auth/email-already-in-use") {
                setValidation("Email already-used")
            }
            
        }
    }

      // ferme la modal et remet le state "message de validation à "vide"  
    const closeModal = () => {
        setValidation("")
        toggleModals("close")
    }
   

  return (
    <>
    {modalState.signUpModal && (

       <div className="container-fluid px-3 bg-transparent">
        
        {/* onClick={() => toggleModals("close")} */}
        <div className="row min-vh-100  ">
          <div className="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
            <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
              <div 
                className="mb-4"><img  className="img-fluid mb-4" src="/img/logo-square.svg" alt="..." style={{maxWidth: '4rem'}} />
                <h2>Pré-inscription</h2>
                {/* <p className="text-muted">A collection of textile samples lay spread out on the table.</p> */}
              </div>
              <form 
                ref={formRef}
                onSubmit={handleForm}
                className="form-validate"
                    >
                    {/* <div className="form-group">
                      <label className="form-label" htmlFor="loginName">Prénom</label>
                      <input className="form-control" name="loginName"  type="text" ref={addInputs} placeholder="votre prénom" autoComplete="off" data-msg="Veuillez compléter ce champ" />
                    </div> */}
                    {/* <div className="form-group">
                      <label className="form-label" htmlFor="loginLastName">Nom</label>
                      <input className="form-control" name="loginLastName"  type="text" onChange={(e) => setDisplayLastName(e.target.value)} placeholder="Votre nom" autoComplete="off" required data-msg="Veuillez compléter ce champ" />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="loginUserCity">Ville </label>
                      <input className="form-control" name="loginUserCity"  type="text" placeholder="votre ville" autoComplete="off"  data-msg="Veuillez compléter ce champ" />
                    </div> */}
                    <div className="form-group">
                      <label className="form-label" htmlFor="loginUserEmail">Email </label>
                      <input className="form-control" name="loginUserEmail"  type="email" ref={addInputs} placeholder="votre@email.com" autoComplete="off"  data-msg="Veuillez compléter ce champ" />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="loginPassword"> Mot de passe</label>
                      <input className="form-control" 
                        name="loginPassword" 
                        type="password" 
                        ref={addInputs} 
                        required 
                        placeholder="Password" 
                        // value={password}
                        // onChange={handleOnChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onKeyUp={handleOnKeyUp}
                        //pattern=" `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`"
                        data-msg="Please enter your password" />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="loginPassword"> Confirmation mot de passe</label>
                      <input className="form-control"
                        name="loginPassword" 
                        type="password" 
                        ref={addInputs} 
                        placeholder="Password" 
                        // onFocus={handleOnFocus}
                        // onBlur={handleOnBlur}
                        required 
                        data-msg="Please enter your password" />
                    </div>
                    <p className="text-danger mt-1">
                        {validation}
                    </p>
                    {pwdRequiste ? (
          <PWDRequisite
            capsLetterFlag={checks.capsLetterCheck ? "valid" : "invalid"}
            numberFlag={checks.numberCheck ? "valid" : "invalid"}
            pwdLengthFlag={checks.pwdLengthCheck ? "valid" : "invalid"}
            specialCharFlag={checks.specialCharCheck ? "valid" : "invalid"}
          />
        ) : null}
                  <button className="btn btn-lg btn-block btn-primary" type='submit' value='Se connecter'>S'incrire</button>
                    {/* <hr className="my-3 hr-text letter-spacing-2" data-content="OR" />
                  <button className="btn btn btn-outline-primary btn-block btn-social mb-3"><i className="fa-2x fa-facebook-f fab btn-social-icon"> </i>Connect <span className="d-none d-sm-inline">with Facebook</span></button>
                  <button className="btn btn btn-outline-muted btn-block btn-social mb-3"><i className="fa-2x fa-google fab btn-social-icon"> </i>Connect <span className="d-none d-sm-inline">with Google</span></button> */}
                    <hr className="my-4" />
                  <p className="text-sm text-muted">En vous inscrivant, vous acceptez les conditions générales et la politique de confidentialité de <Link to="/Cgv">Plats du jour</Link>.</p>
              </form>
              <Link className="close-absolute mr-md-5 mr-xl-6 pt-5" to="/"> 
                <svg onClick={closeModal} className="svg-icon w-3rem h-3rem">
                  <use xlinkHref="#close-1"> </use>
                </svg></Link>
            </div>
            </div>
            <div className="col-md-4 col-lg-6 col-xl-7 d-none d-md-block">
              {/* Image*/}
              <div className="bg-cover h-100 mr-n3 dark-overlay" style={{backgroundImage: 'url(/assets/img/restaurant/AdobeStock_9536945.jpeg)'}} />
            </div>
          </div>
        </div>

 
        
    
        //  <div className="position-fixed top-0 vw-100 vh-100">
        //   <div
        //    onClick={closeModal}
        // //    onClick={() => toggleModals("close")}
        //   className="w-100 h-100 bg-dark bg-opacity-75">
        //   </div>
        //     <div
        //       className="position-absolute top-50 start-50 translate-middle"
        //       style={{ minWidth: "400px" }}
        //     >
        //       <div className="modal-dialog">
        //             <div className="modal-content">
        //                 <div className="modal-header">
        //                     <h5 className="modal-title">Sign Up</h5>
        //                     <button
        //                         className="btn-close"
        //                         onClick={closeModal}>
        //                             {/* onClick={() => toggleModals("close")}> */}
        //                     </button>
        //                 </div>
        //                 <div className="modal-body">

        //                     <form 
        //                         ref={formRef}
        //                         onSubmit={handleForm}
        //                         className="sign-up-form">
        //                         <div className="mb-3">
        //                             <label htmlFor="signUpEmail"
        //                             className='form-label'>Email Adress</label>
        //                             <input
        //                                 ref={addInputs}
        //                                 name='email'
        //                                 required
        //                                 type="email"
        //                                 className='form-control'
        //                                 id='signUpEmail' />
        //                         </div>
        //                         <div className="mb-3">
        //                             <label htmlFor="signUpPwd"
        //                             className='form-label'>Password</label>
        //                             <input
        //                                 ref={addInputs} 
        //                                 name='pwd'
        //                                 required
        //                                 type="password"
        //                                 className='form-control'
        //                                 id='signUpPwd' />
        //                         </div>
        //                         <div className="mb-3">
        //                             <label htmlFor="repeatPwd"
        //                             className='form-label'>Repeat Password</label>
        //                             <input
        //                                 ref={addInputs}
        //                                 name='pwd'
        //                                 required
        //                                 type="password"
        //                                 className='form-control'
        //                                 id='repeatPwd' />
        //                         </div>
        //                         <p className="text-danger mt-1">
        //                             {validation}
        //                         </p>
        //                         <button type='submit' className="btn btn-primary">Submit</button>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>

        //     </div>
        // </div>
        )}
    </>

  )
}
