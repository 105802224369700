const language = {
    FR: {
      home: "Accueil",
      signUp: "Se connecter",
      signIn: "Inscription",
      loGout: " Déconnexion",
      altLogo: "Plats du jour logo"
    },
    EN: {
        home: "Home",
        signUp: "Log Up",
        signIn: "Registration",
        loGout: "Log Out",
        altLogo: "Daily specials logo"
    },
    ES: {
        home: "Bienvenida",
        signUp: "conexión",
        signIn: "Registro",
        loGout: "cerrar sesión",
        altLogo: "Especiales del logo"
    },
  };
  
  export default language;
  