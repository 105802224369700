import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../context/userContext'
import { langContext } from '../context/langContext'
import language from '../assets/language/navBar'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase-config' 
import ToggleLang from './ToggleLang'

export default function NavbarB() {

    const {toggleModals, currentUser} = useContext(UserContext)
    const {lang} = useContext (langContext)

    const navigate = useNavigate()

    const logOut = async () => {
        try {
            await signOut(auth)
            navigate("/")
        } catch (error) {
            alert("For some reasons we can't deconnect, please check your internet connexion and retry")
        }
    }
    const closeModal = () => {
        toggleModals("close")
    }
   


  return (

  <header className="">
  {/* Navbar*/}
  <nav className="navbar navbar-expand-lg fixed-top shadow navbar-light ">
    <div className="container-fluid">
      <div className="d-flex align-items-center" ><Link onClick={closeModal} className="navbar-brand py-1" to="/"><img style={{maxWidth: '10rem'}} src="/assets/img/platsDuJour/logo_blacky.png" alt="Plats du jour logo" /></Link>
         {/* <form className="form-inline d-none d-sm-flex" action="#" id="search">
          <div className="input-label-absolute input-label-absolute-left input-reset input-expand ml-lg-2 ml-xl-3"> 
            <label className="label-absolute" htmlFor="search_search"><i className="fa fa-search" /><span className="sr-only">What are you looking for?</span></label>
            <input className="form-control form-control-sm border-0 shadow-0 bg-gray-200" id="search_search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-reset btn-sm" type="reset"><i className="fa-times fas" /></button>
          </div>
        </form>  */}
      </div>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"><i className="fa fa-bars" /></button>
      {/* Navbar Collapse */}
      
      {currentUser ?
         <button onClick={logOut} className=" btn btn-danger ms-2">{language[lang].logOut}</button>
          :
          <div className="collapse navbar-collapse" id="navbarCollapse">
        {/* <form className="form-inline mt-4 mb-2 d-sm-none" action="#" id="searchcollapsed">
          <div className="input-label-absolute input-label-absolute-left input-reset w-100">
            <label className="label-absolute" htmlFor="searchcollapsed_search"><i className="fa fa-search" /><span className="sr-only">What are you looking for?</span></label>
            <input className="form-control form-control-sm border-0 shadow-0 bg-gray-200" id="searchcollapsed_search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-reset btn-sm" type="reset"><i className="fa-times fas">         </i></button>
          </div>
        </form> */}
        {/* ---------------------------------------------------------------- ToggleLang ------------------------------------------------*/}
        {/* <ToggleLang/> */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item "><Link className="nav-link text-white active" onClick={closeModal}  to="/"  aria-haspopup="true" aria-expanded="false">
              {language[lang].home}</Link>
            {/* <div className="dropdown-menu" aria-labelledby="homeDropdownMenuLink"><a className="dropdown-item" href="index.html">Rooms</a><a className="dropdown-item" href="index-2.html">Restaurants</a><a className="dropdown-item" href="index-3.html">Travel</a><a className="dropdown-item" href="index-4.html">Real Estate <span className="badge badge-info-light ml-1 mt-n1">New</span></a></div> */}
          </li>
          
          
          {/* <li className="nav-item"><a className="nav-link" href="contact.html">Contact</a>
          </li> */}
          {/* <li className="nav-item dropdown"><a className="nav-link dropdown-toggle " id="docsDropdownMenuLink" href="index.html" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Docs</a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="docsDropdownMenuLink">
              <h6 className="dropdown-header font-weight-normal">Documentation</h6><a className="dropdown-item" href="docs/docs-introduction.html">Introduction </a><a className="dropdown-item" href="docs/docs-directory-structure.html">Directory structure </a><a className="dropdown-item" href="docs/docs-gulp.html">Gulp </a><a className="dropdown-item" href="docs/docs-customizing-css.html">Customizing CSS </a><a className="dropdown-item" href="docs/docs-credits.html">Credits </a><a className="dropdown-item" href="docs/docs-changelog.html">Changelog </a>
              <div className="dropdown-divider" />
              <h6 className="dropdown-header font-weight-normal">Components</h6><a className="dropdown-item" href="docs/components-bootstrap.html">Bootstrap </a><a className="dropdown-item" href="docs/components-directory.html">Theme </a>
            </div>
          </li> */}

         {}

          <li className="nav-item"><Link onClick={() => toggleModals("signIn")} className="nav-link text-white" to="/Connect">{language[lang].signUp}</Link></li>
          <li className="nav-item"><Link onClick={() => toggleModals("signUp")} className="nav-link text-white" to="/Connect">{language[lang].signIn}</Link></li>
          
          {/* <li className="nav-item mt-3 mt-lg-0 ml-lg-3 d-lg-none d-xl-inline-block"><a className="btn btn-primary" href="user-add-0.html">Add a listing</a></li> */}
        </ul>
        
      </div>
            }
    </div>
  </nav>
  {/* /Navbar */}
</header>





    // <nav className="navbar navbar-light bg-light px-4">
    //     <Link to="/" className="navbar-brand">
    //         AuthJS
    //     </Link>

    //     <div>
    //         <button 
    //             onClick={() => toggleModals("signUp")}
    //             className=" btn btn-primary">
    //             Sign Up
    //         </button>
    //         <button 
    //             onClick={() => toggleModals("signIn")}
    //             className=" btn btn-primary ms-2 ">
    //             Sign In
    //         </button>
    //         <button 
    //             onClick={logOut}
    //             className=" btn btn-danger ms-2">
    //             Log Out
    //         </button>
    //     </div>
    // </nav>
   

  )
}
