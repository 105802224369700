import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { UserContextProvider } from "./context/userContext";
import ConstextProvider from "./context/langContext";

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(

  <BrowserRouter>
  <ConstextProvider>
    <UserContextProvider>
          <App />
    </UserContextProvider>
  </ConstextProvider>
    
  </BrowserRouter>,

  document.getElementById("root")
);
