import React, {useContext, useRef, useEffect, useState} from 'react'
import { UserContext } from '../context/userContext'
import { langContext } from '../context/langContext'
import language from '../assets/language/SignConnect'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function SignInModal() {

    const {modalState, toggleModals, signIn} = useContext(UserContext)
    const {lang} = useContext (langContext)

    console.log(signIn);
    console.log(modalState, toggleModals);

    const navigate = useNavigate();

    const [validation, setValidation] = useState("");

    const inputs = useRef([])
    const addInputs = el => {
      if(el && !inputs.current.includes(el)){
        inputs.current.push(el)
      }
    }  
    const formRef = useRef();
  


    const handleForm = async (e) => {
        e.preventDefault()

        try {
            const cred = await signIn(
                inputs.current[0].value,
                inputs.current[1].value,
            )

            formRef.current.reset();
            setValidation("")
            //console.dir(cred);
            toggleModals("close")

            navigate("/private/private-home")

        } catch (error) {
            setValidation("Wopsy, email and/or password incorrect")
            toggleModals("close")
            
        } finally {
          
        }
    }

      // ferme la modal et remet le state "message de validation à "vide"  
    const closeModal = () => {
        setValidation("")
        toggleModals("close")
    }
   

  return (
    <>
    {modalState.signInModal && (

        // <div className='login-container' >
        // <div className='login' >
        // <h3>Se connecter</h3>
        // <form 
        //     ref={formRef}
        //     onSubmit={handleForm}
        //     className='form-login' >
        //     <input type='email' placeholder='Email' ref={addInputs} required ></input>
        //     <input type='password' placeholder='Mot de passe' ref={addInputs} required ></input>
        //     <input type='submit' value='Se connecter' ></input>
        //     <p className="text-danger mt-1"> {validation}</p>
        //     {/* <span>{error && "Le mail ou le mot de passe ne correspondent pas"}</span> */}
        // </form>
        // </div>

        // {/* {user ? <CreatePost uid={user.uid} displayName={user.displayName} /> : <ConnectModal/>} */}
        // /
        // </div>

<div className="container-fluid px-3">
<div className="row min-vh-100">
  <div className="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
    <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
      <div className="mb-5"><img className="img-fluid mb-3" src="img/logo-square.svg" alt="..." style={{maxWidth: '4rem'}} />
        <h2>{language[lang].title}</h2>
      </div>
      <form 
        ref={formRef}
        onSubmit={handleForm}
        className="form-validate" >
        <div className="form-group">
          <label className="form-label" htmlFor="loginUsername"> {language[lang].emailAdress}</label>
          <input className="form-control" name="loginUsername"  type="email" ref={addInputs} placeholder="name@address.com" autoComplete="off" required data-msg="Please enter your email" />
        </div>
        <div className="form-group mb-4">
          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="loginPassword"> Password</label>
            </div>
            <div className="col-auto"><a className="form-text small" href="#">Forgot password?</a></div>
          </div>
          <input className="form-control" name="loginPassword" type="password" ref={addInputs} placeholder="Password" required data-msg="Please enter your password" />
        </div>
        <div className="form-group mb-4">
          <div className="custom-control custom-checkbox">
            <input className="custom-control-input" id="loginRemember" type="checkbox" />
            {/* <label className="custom-control-label text-muted" htmlFor="loginRemember"> <span className="text-sm">Remember me for 30 days</span></label> */}
          </div>
        </div>
        <p className="text-danger mt-1"> {validation}</p>
        {/* Submit*/}
        <button type='submit' className="btn btn-lg btn-block btn-primary">{language[lang].btnSignUp}</button>
        {/* <span>{error && "Le mail ou le mot de passe ne correspondent pas"}</span> */}
        {/* <hr className="my-3 hr-text letter-spacing-2" data-content="OR" />
        <button className="btn btn btn-outline-primary btn-block btn-social mb-3"><i className="fa-2x fa-facebook-f fab btn-social-icon"> </i>Connect <span className="d-none d-sm-inline">with Facebook</span></button>
        <button className="btn btn btn-outline-muted btn-block btn-social mb-3"><i className="fa-2x fa-google fab btn-social-icon"> </i>Connect <span className="d-none d-sm-inline">with Google</span></button> */}
        <hr className="my-4" />
        <p className="text-center"><small className="text-muted text-center">{language[lang].txt1} <Link onClick={() => toggleModals("signUp")} to="/Connect">Pré-inscription</Link></small></p>
      </form>
      <Link className="close-absolute mr-md-5 mr-xl-6 pt-5" to="/"> 
        <svg onClick={closeModal} className="svg-icon w-3rem h-3rem">
          <use xlinkHref="#close-1"> </use>
        </svg></Link>
    </div>
  </div>
  <div className="col-md-4 col-lg-6 col-xl-7 d-none d-md-block">
    {/* Image*/}
    <div className="bg-cover h-100 mr-n3 dark-overlay" style={{backgroundImage: 'url(/assets/img/restaurant/AdobeStock_9536945.jpeg)'}} />
  </div>
</div>
</div>

        
    
        //  <div className="position-fixed top-0 vw-100 vh-100">
        //   <div
        //    onClick={closeModal}
        // //    onClick={() => toggleModals("close")}
        //   className="w-100 h-100 bg-dark bg-opacity-75">
        //   </div>
        //     <div
        //       className="position-absolute top-50 start-50 translate-middle"
        //       style={{ minWidth: "400px" }}
        //     >
        //       <div className="modal-dialog">
        //             <div className="modal-content">
        //                 <div className="modal-header">
        //                     <h5 className="modal-title">Sign In</h5>
        //                     <button
        //                         className="btn-close"
        //                         onClick={closeModal}>
        //                             {/* onClick={() => toggleModals("close")}> */}
        //                     </button>
        //                 </div>
        //                 <div className="modal-body">

        //                     <form 
        //                         ref={formRef}
        //                         onSubmit={handleForm}
        //                         className="sign-up-form">
        //                         <div className="mb-3">
        //                             <label htmlFor="signInEmail"
        //                             className='form-label'>Email Adress</label>
        //                             <input
        //                                 ref={addInputs}
        //                                 name='email'
        //                                 required
        //                                 type="email"
        //                                 className='form-control'
        //                                 id='signInEmail' />
        //                         </div>
        //                         <div className="mb-3">
        //                             <label htmlFor="signUpPwd"
        //                             className='form-label'>Password</label>
        //                             <input
        //                                 ref={addInputs} 
        //                                 name='pwd'
        //                                 required
        //                                 type="password"
        //                                 className='form-control'
        //                                 id='signInPwd' />
        //                         </div>
        //                         <p className="text-danger mt-1">
        //                             {validation}
        //                         </p>
        //                         <button type='submit' className="btn btn-primary">Submit</button>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>

        //     </div>
        // </div>
        )}
    </>

  )
}
